@import '@assets/components_base';

.root {
  // scroll-snap-align: end;
  position: relative;
  min-height: 140vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: $white;

  @include md_ {
    min-height: 100vh;
  }
}

.hideFooter {
  display: none;
}

.link {
  & > svg {
  }

  &:hover > svg {
  }
}

.fadeContainer {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  // z-index: -1;
  max-height: 140vh;

  @include md_ {
    max-height: 100vh;
  }
}

.fade {
  object-fit: cover;
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  padding: 2rem 0;
  margin: 0 var(--standard-margin);
  position: relative;
  z-index: 1;

  @include md_ {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.gridBottom {
  align-items: flex-end;
}

.col1 {
  grid-column: span 1;

  @include md_ {
    grid-column: span 4;
  }
}

.col2 {
  grid-column: span 1;

  @include md_ {
    grid-column: span 4;
    display: flex;
    justify-content: center;
  }
}

.col3 {
  grid-column: span 1;

  @include md_ {
    grid-column: span 4;
    display: flex;
    justify-content: flex-end;
  }
}

.logo {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.detail {
  @include content-type-2;
  margin-bottom: 2em;
  color: $white;
  white-space: break-spaces;

  a {
    color: $blue;
    text-decoration: none;

    @include hover {
      opacity: $default-opacity;
    }
  }
}

.powered {
  @include content-type-2;
  align-self: flex-end;
}

.morphic {
  display: inline-block;
  position: relative;
  height: rem-property(20px);
  width: rem-property(109px);
  margin-left: 1ch;
}

.detailLinks {
  @include content-type-2;
  margin-bottom: 2em;
  a {
    display: block;
    color: $blue;

    @include hover {
      opacity: $default-opacity;
    }
  }
}

.copywrite {
  @include content-type-2;
}

.social {
  @include content-type-2;
  display: flex;
  flex-direction: column;

  @include md_ {
    flex-direction: row;
  }

  a {
    margin-right: 2ch;
    @include md_ {
      margin-left: 2ch;
      margin-right: 0;
    }
    @include hover {
      opacity: $default-opacity;
    }
  }
}

.newsletterContainer {
  width: 100%;
  margin-left: auto;

  @include md_ {
    width: rem-property(427px);
  }
}

.newsletterTitle {
  @include content-type-2;
}

.newsletter {
  margin: 0.5em 0;
}
