@import '@assets/components_base';

.root {
  margin: 0 auto;
  width: 100%;
}

.fixedWidth {
  margin: 0 auto;
  width: 100%;
  max-width: var(--max-width);
}
