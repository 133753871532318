@import '@assets/components_base';

.root {
  position: relative;
  display: flex;
  align-items: center;
}

.list {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
}

.item {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}


.item:hover {
}

.item:first-child {
}

.item:focus,
.item:active {
}

.bagCount {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;

  padding-left: 2.5px;
  padding-right: 2.5px;
  min-width: 1.25rem;
  min-height: 1.25rem;
}

.mobileMenu {
  display: flex;

  @include md_ {
    display: none;
  }
}

.avatarButton:focus,
.mobileMenu:focus {
}

.dropdownDesktop {
  display: none;

  @include md_ {
    display: block;
    z-index: -10;
  }
}


.hamburger {
  color: $black;
}

.hamburgerLight {
  color: $white;
}