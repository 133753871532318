@import '@assets/components_base';

.root {
  cursor: pointer;
  display: inline-flex;
  padding: 6px 15px 7px;
  text-align: center;
  justify-content: center;
  border: 1px solid $blue;
  align-items: center;
  max-height: 64px;
  background: $blue;
  color: $white;
  border-radius: 47px;
  transition: background-color var(--standardTransitionTime),
    color var(--standardTransitionTime),
    border-color var(--standardTransitionTime);
  width: auto;
  white-space: nowrap;

  @include md_ {
    padding: rem-property(4px) rem-property(35px) rem-property(7px);
    border-radius: rem-property(47px);
    white-space: nowrap;
    width: auto;
  }

  @include hover {
    background: $blue-dark;
    border-color: $blue-dark;
  }
}

.root:hover {
  // @apply border-accent-9 bg-accent-6;
}

.root:focus {
  // @apply shadow-outline-normal outline-none;
}

.root[data-active] {
  // @apply bg-accent-6;
}

.loading {
  // @apply bg-accent-1 text-accent-3 border-accent-2 cursor-not-allowed;
}

.mono {
  border: 1px solid $grey;
  background: $grey;

  @include md_ {
    min-width: rem-property(170px);
  }

  @include hover {
    background: $grey-dark;
    border-color: $grey-dark;
  }
}

.ghost {
  border: 1px solid $black;
  background: $black;

  @include md_ {
    min-width: rem-property(170px);
  }

  @include hover {
    background: $grey-dark;
    border-color: $grey-dark;
  }
}

.naked {
  border: 1px solid $white;
  background: transparent;

  @include hover {
    border-color: $blue;
    color: $blue;
    background: transparent;
  }
}

.naked:hover,
.naked:focus {
  // @apply bg-transparent border-none;
}

.blackNaked {
  border: 1px solid $black;
  background: transparent;
  color: $black;

  @include hover {
    border-color: $black;

    background: transparent;
  }

  &[data-active='true'] {
    background: $black;
    color: $white;
  }
}

.blackNaked:hover,
.blackNaked:focus {
  // @apply bg-transparent border-none;
}

.text {
  @include content-type-2;
  border: none;
  background: none;
  padding: 0;
  color: $grey;
  text-decoration: underline;
  transition: none;

  @include md_ {
    min-width: unset;
  }

  @include hover {
    background: none;
    border-color: none;
    opacity: $default-opacity;
    text-decoration: none;
  }
}
  
.none {
  @include content-type-1;
  border: none;
  background: none;
  padding: 0;
  color: $grey;
  transition: none;

  @include md_ {
    min-width: unset;
  }

  @include hover {
    background: none;
    border-color: none;
    opacity: $default-opacity;
  }
}
  

.disabled,
.disabled:hover {
  // @apply text-accent-4 border-accent-2 bg-accent-1 cursor-not-allowed;
  filter: grayscale(1);
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.progress {
}

.icon {
  padding-left: 1rem;
  margin: 0;
  display: flex;
}
