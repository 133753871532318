@import '@assets/components_base';

.root {
  position: absolute;
  top: 0;
  z-index: 40;
  min-height: $header-small-height;
  width: 100%;

  @include md_ {
    min-height: $header-height;
  }
}

.hidden {
  display: none;
}

.container {
  // background: $black;

  padding: var(--standard-margin);

  // max-width: 1920px;

  margin-left: auto;
  margin-right: auto;
  transition: color var(--standardTransitionTime);
}

.darkMode {
  color: $black;
}

.nav {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navMenu {
  display: none;

  @include md_ {
    display: block;
    margin: 0;
  }
}

.links {
  display: inline-flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
}

.link {
  margin-right: rem-property(50px);

  &:last-child {
    margin-right: 0;
  }
  &.active {
    color: $blue;
  }
}

.link:hover {
  opacity: $default-opacity;
}

.link:focus {
  outline: none;
}

.logo {
  cursor: pointer;
  width: 165px;
  height: 40px;

  @include md_ {
    width: rem-property(221px);
    height: rem-property(54px);
  }

  @include hover {
    // transform: scale(1.05);
  }
}

.logoImage {
  width: 100%;
  height: 100%;

  path {
    transition: fill var(--standardTransitionTime);
  }
}

.accountContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // flex: 1;
}

.buttonActive {
  border-color: $blue;
}
