@import '@assets/components_base';

.root {
  display: flex;
  flex-direction: row;
  height: 2.25rem;
}

.actions {
  display: flex;
  padding: 1rem;
  border: 1px solid $black;
  align-items: center;
  justify-content: center;
  width: 3rem;
  background: none;
  color: $white;

  transition-property: border-color, background, color, transform, box-shadow;

  transition-duration: 0.15s;
  transition-timing-function: ease;
  user-select: none;
}

.actions:hover {
  border: 1px solid $black;

  transition: border-color;
  z-index: 10;
}

.actions:focus {
  outline: none;
}

.actions:disabled {
  cursor: not-allowed;
}

.input {
  background: transparent;
  padding: 0 1rem;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: auto;
  border: $standard-border;
  color: $white;

  &:focus {
    outline: none;
  }
}

.label {
  width: 100%;
  border: 1px solid $black;
  margin-left: 0.5rem;
}
