@import '@assets/components_base';

.root {
  border: 1px solid $black;

  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
