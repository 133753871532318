@import '@assets/components_base';

.form {
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.75rem;
  gap: 0.75rem;
}

.logo {
  display: flex;
  padding-bottom: 3rem;
  justify-content: center;
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.error {
  color: $red;
  border: 1px solid $red;
  padding: 0.75rem;
}

.link {
  display: inline;
  cursor: pointer;
  @include hover {
    text-decoration: underline;
  }
}


.buttonContainer {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.footer {
    padding-top: 0.75rem;
    text-align: center;
}

.info {
    
    display: inline-block;
    vertical-align: middle;
}