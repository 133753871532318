@import '@assets/components_base';

.placeholder {
  @include fit-parent;
  width: 100%;
  height: 100%;
  z-index: 1;
  rect {
    width: 100%;    
    height: 100%;
    // fill: var(--color-off-white);
    // transform: translate3d(0, 0, 0);
    // will-change: transform;
  }
}

.container {
  display: flex;
//   position: relative;
}

.containerBlock {
  display: block;
//   position: relative;
}
