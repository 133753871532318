@import '@assets/components_base';

.root {
  width: 100%;
  flex: 1;
  z-index: 20;

  padding: var(--standard-margin);
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 0;
}

.item {
  border-top: $standard-border;
  padding: 20px 0;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: -1;
}

.imageContainer {
  position: relative;
  height: calc(100% - #{rem-property(146px)});
  margin-bottom: rem-property(146px);
}

.fade {
  position: absolute;
  bottom: 146px;
  left: 0;
  right: 0;
  z-index: 1;
}
