@import '@assets/components_base';

.fieldset {
  display: flex;
  flex-direction: column;
  margin: 0.75rem 0;
}

.fieldset .label {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.fieldset .input,
.fieldset .select {
  padding: 0.75rem;
  border: 1px solid $black;
  width: 100%;

}

.fieldset .input:focus,
.fieldset .select:focus {
}

.form {
  height: 100%;
}