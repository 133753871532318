@import '@assets/components_base';

.root {
  //   background: $white;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  box-sizing: border-box;
  height: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.container {
  position: absolute;
  //   left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;

  display: flex;
  outline: none;
  max-width: 100%;
  @include md_ {
    width: auto;
    padding-left: 2.5rem;
  }
}

.containerInner {
  height: 100%;
  width: 100%;

  @include md_ {
    width: 100vw;
    max-width: 28rem;
  }
}
.sidebar {
  display: flex;
  flex-direction: column;
  // background: var(--color-primary);

  -webkit-overflow-scrolling: touch !important;

  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.8px);

  transition: opacity 0.7s;
  //   -webkit-backdrop-filter: blur(0.8px);
}
