@import '@assets/components_base';

.root {
  max-width: 100%;
}

.heading {
  @include header-1;
}

.pageHeading {
  @include header-1;
  padding-top: 0;
  padding-bottom: 0;

  p {
    margin: 0 0 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sectionHeading {
  @include header-2;
  // cursor: pointer;
  margin-bottom: 1em;
}

.sectionHeading2 {
  @include header-2-small;
  // cursor: pointer;
  margin-bottom: 1em;
}

.sectionHeadingV2 {
  @include sectionHeadingV2;
  margin-bottom: 1em;
}

.contentHeading {
  @include header-3;
  margin-bottom: 1em;
}

.smallText {
  @include content-type-2;
}

.smallTextSmall {
  @include content-type-2;
  font-size: 10px;

  @include md_ {
    @include content-type-2;
  }
}

.text3 {
  @include content-type-3;
}

/* Apply base font sizes and styles for typography markup (h2, h2, ul, p, etc.).
     A helpful addition for whenn page content is consumed from a source managed through a wysiwyg editor. */

.root :is(h1, h2, h3, h4, h5, h6, p, ul, ol) {
  margin-bottom: 1rem;
}

.root :is(h1, h2, h3, h4, h5, h6):not(:first-child) {
  margin-top: 8px;
}

.root:is(h1, h2, h3, h4, h5, h6) {
  // display: flow-root;

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// .root h1 {
// }

// .root {
//   &:is(h2) {
//   }
// }

// .root h3 {
// }

// .root h4 {
// }

// .root h5 {
// }

// .root h6 {
// }

// .root ul,
// .root ol {
// }

.root ul {
  list-style: disc;
}

.root ol {
  list-style: decimal;
}

.root a {
  // text-decoration: underline;
  color: $blue;
}

.root a:hover {
  text-decoration: underline;
}

.root {
  :global .small-text-size {
    @include content-type-2;
  }
}
