@import '@assets/components_base';

.root {
  border: 1px solid $black;
  padding: 5px 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
