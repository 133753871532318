@import '@assets/components_base';

.root {
  position: relative;
  // height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background: var(--color-primary);
  color: $white;
}

.header {
  min-height: 66px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  padding: var(--standard-margin);

  @include md_ {
    min-height: 74px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.button {
  display: flex;
  align-items: center;
  margin-right: 0;
  background: transparent;
  color: $white;

  @include md_ {
  }

  &:focus {
    outline: none;
  }
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
}

.iconTexxt {
  margin-left: 1.25rem;
}

.logo {
  cursor: pointer;
  width: 165px;
  height: 40px;
  margin: 0 0 auto;
}

.logoImage {
  width: 100%;
  height: 100%;
}
