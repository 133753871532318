@import '@assets/components_base';

.root {
  min-height: calc(100vh - 322px);
}

.lineItemsList {
  padding: 1rem 0;
  gap: 1.5rem;
}


.totalItem {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.form {
  flex-shrink: 0;
  padding: 1.5rem 1.5rem;
  position: sticky;
  z-index: 20;
  bottom: 0;
  width: 100%;
  right: 0;
  left: 0;
  border-top: 1px solid $black;
}

.totalContainer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $black;
  padding: 1.5rem 0;
  margin-bottom: 1.5rem;
}