@import '@assets/components_base';

.root {
  height: 100%;
  margin: 0 auto;
  //   max-width: 2460px;
}

.main {
  min-height: calc(100vh - 88px);
}

.loading {
  width: 200rem;
  height: 200rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 1rem;
}

.cookies {
  margin: 0 1.1rem;
}

.darkTheme {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.lightTheme {
  background-color: var(--color-white);
  color: var(--color-primary);
}
