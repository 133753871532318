@import '@assets/components_base';

.root {
  .findFitterContainer {
    padding: 1rem 1.5rem;

    flex: 1 1;

    display: flex;
    flex-direction: column;
    align-content: flex-start;
  }

  .backToCart {
    color: $white;

    display: flex;
    justify-content: flex-start;
  }

  .title {
    padding-top: 2rem;
    text-align: center;
  }

  .description {
    padding: 1rem 4rem 0;
    text-align: center;
  }

  .collapseHeader {
    border: $standard-border;
    position: relative;

    &.active {
      border-bottom: none;
    }
  }

  .collapseBody {
    display: none;

    &.active {
      display: block;
    }
  }

  .fittersForm {
    height: 100%;

    input[type='radio'] {
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }

    input[type='text'],
    input[type='email'] {
      @include content-type-2;
      border: none;
      background: $white;
      border-radius: 4px;
      margin-bottom: 1rem;
      padding: 0.4rem;
      width: 100%;
    }

    input:checked + label {
      &:before {
        background: $white;
      }
    }

    label {
      cursor: pointer;
      position: relative;
      z-index: 1;
      padding-left: 2rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: block;
      &:before {
        position: absolute;
        left: 0.5rem;
        top: 1rem;
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        border: $standard-border;
      }

      p {
        margin: 0;
      }
    }

    .fittersOverflow {
      // overflow: auto;

      // height: 100%;
      // min-height: 5rem;
      // max-height: calc(100vh - 25rem);

      border: $standard-border;
    }

    .fitter {
      position: relative;
      border-bottom: $standard-border;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .fitterSuggestContainer {
    .collapseHeader {
      border-top: none;
    }

    .collapseBody {
      padding: 0.5rem;
    }
    .choiceTitle {
      margin-bottom: 1rem;
    }
  }

  .totalsContainer {
    flex-shrink: 0;
    padding: 1.5rem 1.5rem;
    position: sticky;
    z-index: 20;
    bottom: 0;
    width: 100%;
    right: 0;
    left: 0;
    border-top: 1px solid $black;
    background: $black;
  }
  .checkoutButton {
    pointer-events: none;

    &.checkoutButtonActive {
      pointer-events: all;
    }
  }
}
