@import '@assets/components_base';

.root {
  padding: 0.5rem 1.5rem;
  width: 100%;
  appearance: none;
  border: 1px solid $black;
  padding-right: 10px;
}

.root:focus {
  outline: none;
}
