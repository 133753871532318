// Misc utilities

@mixin visuallyHidden {
  position: absolute !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
}

// Page initially loads with 'no-js' class on the body.
// As soon as JS loads (via a script in the header),
// it should replace that class with 'js'
@mixin no-js-hide {
  .no-js & {
    display: none !important;
  }
}
@mixin js-hide {
  .js & {
    display: none !important;
  }
}

@mixin list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

@mixin fit-parent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin fixed {
  position: fixed;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

@mixin background-image {
  height: 100%;
  width: 100%;
  position: relative;
  img {
    object-position: center top;
  }
}

@mixin foreground-content {
  @include fit-parent;
  z-index: 1;
}

@mixin two-columns {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $desktop-margin / 2;

  @include md_ {
    grid-template-columns: 1fr 1fr;
  }
}

@mixin button {
  @include content-type-3;
  background: $black;
  border-radius: rem-property(10px);
  padding: rem-property(20px);
  color: $white;
  border: rem-property(1px) solid $black;
  outline: none;

  @include hover {
    background: $white;
    color: $black;
  }

  &:focus {
    -webkit-tap-highlight-color: transparent;
  }
}

@mixin checkbox {
  width: 100%;
  appearance: none;
  border: $black-border;
  border-radius: rem-property(7px);
  //   width: rem-property(22px);
  height: rem-property(22px);
  padding: 0;
  cursor: pointer;

  &:checked {
    background: $black;
    position: relative;
    &::after {
      content: ('');
      background-image: url('/tick.svg');
      background-size: rem-property(12px);
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      z-index: 1;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  &:focus {
    outline: none;
  }
}
