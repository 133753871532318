@import '@assets/components_base';

.root {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.root:first-child {
  padding-top: 0;
}

.quantity {
  appearance: textfield;
  width: 8px;
  margin: 0 0.75rem;
  border-radius: 50%;
  text-align: center;
}

.quantity::-webkit-outer-spin-button,
.quantity::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.productImage {
  width: 100%;
  height: 100%;
}

.productNameContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.productName {
  cursor: pointer;
  padding-bottom: 1rem;
  margin-top: -1rem;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  gap: rem-property(20px);
}

.imageInner {
  width: 4rem;
  height: 4rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 0;
}

.options {
}

.option {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.optionName {
  flex-basis: 50%;
}

.optionValue {
  flex-basis: 50%;
  // padding: 1rem;
  // display: inline-flex;
  // align-items: center;
  // justify-content: center;
  overflow: hidden;
}

.optionLast {
  margin-right: 1.5rem;
  flex-basis: 15%;
}

.variantDisplay {
}

.price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 22.5%;
}
